<template>
  <div :class="category">
    <a class="producto" v-bind:href="detalle">
      <img v-bind:src="image" :alt="title">
      <h2>{{ title }}</h2>
      <h2>{{ price }}</h2>
    </a>
  </div>

</template>

<script>
export default {
  name: 'Productos',
  props: ['detalle', 'material' ,'image', 'thumbnail_1', 'thumbnail_2', 'thumbnail_3', 'title', 'category', 'price'],
  
}
</script>