<template>
  <div class="productos">
    <h1>Productos Pimatex</h1>
    <div class="filtros flex">
        <h2>PRODUCTOS</h2>
        <form id="filter" class="flex" action="">
            
            <label for="">Categorías</label>
            <select class="formulario_filtro">
                <option value="todos">Todos</option>
                <option value="hilo">Hilo</option>
                <option value="hilaza">Hilaza Poliester</option>
                <option value="hilaza">Hilaza Nylon</option>
                <option value="otro">Otros Insumos</option>
            </select>
    
          
        </form>

    </div>
  
              
          
            
    <section class="productos_hilos">

              <div v-for="(producto) in productos"  :class="categoria = producto.categoria" :key="producto.id" @click="producto.price += 1">
                <router-link :to="'/productos/' + producto.id">
                  
                    <img :src="producto.thumbnail" alt="">
                    <h2 class="nombre_producto">{{ producto.nombre }}</h2>
                  
                </router-link>
              </div>
      
           <!--  <Productos v-for="(perro) in perdros"
                :key="perro.id" @click="price += 1"
                :category="perro.category"
                :image="perro.image"
                :title="perro.title"
                :price="perro.price"
            ></Productos><Product :perro="detailProduct"></Product>

            

            <Productos v-for="(hilaza) in hilazas"
                :key="hilaza.id"
                :category="hilaza.category"
                :detalle="hilaza.detalle"
                :image="hilaza.image"
                :title="hilaza.title"
                :price="hilaza.price"
            ></Productos>

            <Productos v-for="(otro) in otros"
            :key="otro.id"
            :category="otro.category"
            :detalle="otro.detalle"
            :image="otro.image"
            :title="otro.title"
            :price="otro.price"
        ></Productos>
        -->

        </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Productos from '@/components/Component_products.vue'
import Product from '@/components/Component_product.vue'

export default {
  name: 'Eje',
  components: {
    Productos,
    Product
  },
  data() {
    return{
     productos: [
        { 
          id: 1, 
          nombre: 'Aceite siliconado para maquina', 
          categoria: 'otros', 
          medida:'250 ML, 1/4 de Galon, medio galon, galon', 
          calibre:'N/A', 
          gramaje:'N/A',  
          caracteristicas:'ACEITE SILICONADO PARA MAQUINA INDUSTRIAL , NO MANCHA LAS PRENDAS.', 
          colores:'N/A',
          image:'assets/images/fotos/aceite.jpg',
          thumbnail:'assets/images/miniaturas/aceite.jpg',
          thumbnail_1: '', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        { 
          id: 2, 
          nombre: 'Agujas para maquina collarin', 
          categoria: 'otros', 
          medida:'70/10, 80/12, 90/14, 100/16, 110/18', 
          calibre:'N/A', 
          gramaje:'N/A',  
          caracteristicas:'PUNTA DE BOLA', 
          colores:'N/A',
          image:'assets/images/fotos/agujas_collarin.jpg',
          thumbnail:'assets/images/miniaturas/agujas_collarin.jpg',
          thumbnail_1: '', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },

        { 
          id: 3, 
          nombre: 'Agujas para maquina filete', 
          categoria: 'otros', 
          medida:'70/10, 80/12, 90/14, 100/16, 110/18', 
          calibre:'N/A', 
          gramaje:'N/A',  
          caracteristicas:'PUNTA DE BOLA', 
          colores:'N/A',
          image:'assets/images/fotos/agujas_filete.jpg',
          thumbnail:'assets/images/miniaturas/agujas_filete.jpg',
          thumbnail_1: '', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        { 
          id: 4, 
          nombre: 'agujas para maquina plana, cubo delgado', 
          categoria: 'otros', 
          medida:'70/10, 80/12, 90/14, 100/16, 110/18', 
          calibre:'N/A', 
          gramaje:'N/A',  
          caracteristicas:'PUNTA DE BOLA', 
          colores:'N/A',
          image:'assets/images/fotos/agujas_maquina_plana_cubo_delgado.jpg',
          thumbnail:'assets/images/miniaturas/agujas_maquina_plana_cubo_delgado.jpg',
          thumbnail_1: '', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        { 
          id: 5, 
          nombre: 'Agujas para maquina plana, cubo grueso', 
          categoria: 'otros', 
          medida:'70/10, 80/12, 90/14, 100/16, 110/18', 
          calibre:'N/A', 
          gramaje:'N/A',  
          caracteristicas:'PUNTA DE BOLA', 
          colores:'N/A',
          image:'assets/images/fotos/agujas_maquina_plana_cubo_grueso.jpg',
          thumbnail:'assets/images/miniaturas/agujas_maquina_plana_cubo_grueso.jpg',
          thumbnail_1: '', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        
        { 
          id: 6, 
          nombre: 'Plastiflechas trabajo liviano.', 
          categoria: 'otros', 
          medida:'1 PULGADA', 
          calibre:'N/A', 
          gramaje:'N/A',  
          caracteristicas:'PLATIFLECHAS TRABAJO LIVIANO,CAJA X 5000 UNIDADES', 
          colores:'N/A',
          image:'assets/images/fotos/plastiflechas_trabajo_liviano.jpg',
          thumbnail:'assets/images/miniaturas/plastiflechas_trabajo_liviano.jpg',
          thumbnail_1: '', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        
        { 
          id: 7, 
          nombre: 'Rematador metálico.', 
          categoria: 'otros', 
          medida:'1 PULGADA', 
          calibre:'N/A', 
          gramaje:'N/A',  
          caracteristicas:'PLATIFLECHAS TRABAJO LIVIANO,CAJA X 5000 UNIDADES', 
          colores:'N/A',
          image:'assets/images/fotos/rematador_metalico.jpg',
          thumbnail:'assets/images/miniaturas/rematador_metalico.jpg',
          thumbnail_1: 'assets/images/miniaturas/rematador_metalico.jpg', 
          thumbnail_2: 'assets/images/miniaturas/rematador_metalico_2.jpg', 
          thumbnail_3: ''
        },
        { 
          id: 8, 
          nombre: 'Rematador plastico', 
          categoria: 'otros', 
          medida:'N/A', 
          calibre:'N/A', 
          gramaje:'N/A',  
          caracteristicas:'N/A', 
          colores:'rojo, verde',
          image:'assets/images/fotos/rematador_plastico.jpg',
          thumbnail:'assets/images/miniaturas/rematador_plastico.jpg',
          thumbnail_1: 'assets/images/miniaturas/rematador_plastico.jpg', 
          thumbnail_2: 'assets/images/miniaturas/rematador_plastico.jpg', 
          thumbnail_3: ''
        },
        { 
          id: 9, 
          nombre: 'Rematador plastico de oreja', 
          categoria: 'otros', 
          medida:'N/A', 
          calibre:'N/A', 
          gramaje:'N/A',  
          caracteristicas:'N/A', 
          colores:'Verde, Gris, lila',
          image:'assets/images/fotos/rematador_plastico_de_oreja.jpg',
          thumbnail:'assets/images/miniaturas/rematador_plastico_de_oreja.jpg',
          thumbnail_1: 'assets/images/miniaturas/rematador_plastico_de_oreja.jpg', 
          thumbnail_2: 'assets/images/miniaturas/rematador_plastico_de_oreja_2.jpg', 
          thumbnail_3: ''
        },
        { 
          id: 10, 
          nombre: 'Tijera Las Pima', 
          categoria: 'otros', 
          medida:'5.5 pulgadas, 8.5 pulgadas', 
          calibre:'N/A', 
          gramaje:'N/A',  
          caracteristicas:'N/A', 
          colores:'Verde, Gris, lila',
          image:'assets/images/fotos/Tijera_Las_Pima_de_5-5_pulgadas.jpg',
          thumbnail:'assets/images/miniaturas/Tijera_Las_Pima_de_5-5_pulgadas.jpg',
          thumbnail_1: 'assets/images/miniaturas/Tijera_Las_Pima_de_5-5_pulgadas.jpg', 
          thumbnail_2: 'assets/images/miniaturas/Tijera_Las_Pima_de_8-5_pulgadas.jpg', 
          thumbnail_3: ''
        },
        { 
          id: 11, 
          nombre: 'Hilaza Nylon Elastica', 
          categoria: 'Hilaza', 
          medida:'N/A', 
          calibre:'N/A', 
          gramaje:'100gr ,200gr , 500gr',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilaza_nylon_elastica_200_gramos_blanca_cruda.jpg',
          thumbnail:'assets/images/miniaturas/hilaza_nylon_elastica_200_gramos_blanca_cruda.jpg',
          thumbnail_1: 'assets/images/miniaturas/hilaza_nylon_elastica_200_gramos_color.jpg', 
          thumbnail_2: 'assets/images/miniaturas/hilaza_nylon_elastica_200 gramos_color_2.jpg', 
          thumbnail_3: 'assets/images/miniaturas/hilaza_nylon_elastica_200_gramos_blanca_cruda.jpg'
        },
        { 
          id: 12, 
          nombre: 'Hilaza Poliester por Torta ', 
          categoria: 'Hilaza', 
          medida:'N/A', 
          calibre:'N/A', 
          gramaje:'4 kilos aproximadamente',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilaza_nylon_elastica_200_gramos_blanca_cruda.jpg',
          thumbnail:'assets/images/miniaturas/hilaza_poliester_por_torta_en_1cabo_y_2_cabos.jpg',
          thumbnail_1: 'assets/images/miniaturas/hilaza_poliester_por_torta_en_1cabo_y_2_cabos.jpg', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        { 
          id: 13, 
          nombre: 'Hilaza Poliester por Kilo', 
          categoria: 'Hilaza', 
          medida:'N/A', 
          calibre:'N/A', 
          gramaje:'1 kilo',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilaza_por_kilo_en_1_cabo_y_2_cabos.jpg',
          thumbnail:'assets/images/miniaturas/hilaza_por_kilo_en_1_cabo_y_2_cabos.jpg',
          thumbnail_1: 'assets/images/miniaturas/hilaza_por_kilo_en_1_cabo_y_2_cabos.jpg', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        { 
          id: 14, 
          nombre: 'Hilaza Poliester x 200 gramos', 
          categoria: 'Hilaza', 
          medida:'N/A', 
          calibre:'N/A', 
          gramaje:'200 gramos',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilaza_poliester_200_gramos_en_1_cabo_y_2_cabos.jpg',
          thumbnail:'assets/images/miniaturas/hilaza_poliester_200_gramos_en_1_cabo_y_2_cabos.jpg',
          thumbnail_1: 'assets/images/miniaturas/hilaza_poliester_200_gramos_en_1_cabo_y_2_cabos.jpg', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        { 
          id: 15, 
          nombre: 'Hilaza Poliester x 300 gramos', 
          categoria: 'Hilaza', 
          medida:'N/A', 
          calibre:'N/A', 
          gramaje:'300 gramos',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilaza_poliester_300_gramos_1_cabo_y_2_cabos.jpg',
          thumbnail:'assets/images/miniaturas/hilaza_poliester_300_gramos_1_cabo_y_2_cabos.jpg',
          thumbnail_1: 'assets/images/miniaturas/hilaza_poliester_300_gramos_1_cabo_y_2_cabos.jpg', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        { 
          id: 16, 
          nombre: 'Hilaza Poliester x 500 gramos', 
          categoria: 'Hilaza', 
          medida:'N/A', 
          calibre:'N/A', 
          gramaje:'500 gramos',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilaza_poliester_500_gramos_en_1_cabo_y_2_cabos.jpg',
          thumbnail:'assets/images/miniaturas/hilaza_poliester_500_gramos_en_1_cabo_y_2_cabos.jpg',
          thumbnail_1: 'assets/images/miniaturas/hilaza_poliester_500_gramos_en_1_cabo_y_2_cabos.jpg', 
          thumbnail_2: 'assets/images/miniaturas/hilaza_poliester_500_gramos_1_cabo_y_2 cabos_2.jpg', 
          thumbnail_3: ''
        },
        { 
          id: 17, 
          nombre: 'Hilo calibre 120 en 2.000 yardas', 
          categoria: 'Hilo', 
          medida:'2000 Yardas, 5000 Yardas, 10.000 Yardas', 
          calibre:'120, 75, 50, 30', 
          gramaje:'N/A',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilo_calibre_120_en_2000_yardas.jpg',
          thumbnail:'assets/images/miniaturas/hilo_calibre_120_en_2000_yardas.jpg',
          thumbnail_1: 'assets/images/miniaturas/hilo_calibre_120_en_2000_yardas.jpg', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },

        { 
          id: 18, 
          nombre: 'Hilo calibre 120 en 5.000 yardas', 
          categoria: 'Hilo', 
          medida:'2000 Yardas, 5000 Yardas, 10.000 Yardas', 
          calibre:'120, 75, 50, 30', 
          gramaje:'N/A',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilo_calibre_120_5000_yardas.jpg',
          thumbnail:'/assets/images/miniaturas/hilo_calibre_120_5000_yardas.jpg',
          thumbnail_1: 'assets/images/miniaturas/hilo_calibre_120_5000_yardas.jpg', 
          thumbnail_2: 'assets/images/miniaturas/hilo_calibre_120_5000_yardas_2.jpg', 
          thumbnail_3: ''
        },
        { 
          id: 19, 
          nombre: 'Hilo calibre 120 en 10.000 yardas', 
          categoria: 'Hilo', 
          medida:'2000 Yardas, 5000 Yardas, 10.000 Yardas', 
          calibre:'120, 75, 50, 30', 
          gramaje:'N/A',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilo_calibre_120_5000_yardas.jpg',
          thumbnail:'assets/images/miniaturas/hilo_calibre_120_10000_yardas.jpg',
          thumbnail_1: 'assets/images/miniaturas/hilo_calibre_120_10000_yardas.jpg', 
          thumbnail_2: 'assets/images/miniaturas/hilo_calibre_120_10000_yardas_2.jpg', 
          thumbnail_3: ''
        },
        
      ],
      
    }
  },
  mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'assets/appPimatex.js')
      document.head.appendChild(recaptchaScript)
    }
  
}



</script>