<template>
    <!-- <div>
        <h2>{{ title }}</h2>
        <h1>ejemplo</h1>
        {{ $route.params.id }}
        <h1>Detalle</h1>
        <img v-bind:src="image" :alt="title">
        
    </div>-->

    <section class="flex container_detalle">
                
                    
            
        </section>
</template>


<script>
export default {
    name: 'Product',
    props: ['perro', 'image','title']
}
</script>